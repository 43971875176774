<template>
  <div class="order-create__section">
    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col">
        <div class="order-create__section-label section-label"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['marketplaceSettings_other'])"></div>
          {{ $t('marketplaceSettings_other.localization_value.value') }}
        </div>

        <div class="order-create__row custom-row">
          <div class="order-create__col custom-col custom-col--50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['marketplaceSettings_minAmount'])"></div>
            <DefaultInput
                :label="$t('marketplaceSettings_minAmount.localization_value.value')"
                v-model="MS.data.minOrderAmount"
            />
          </div>
        </div>

        <div class="order-create__row custom-row">
          <div class="order-create__col custom-col custom-col--50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['marketplaceSettings_googleAnalyticsId'])"></div>
            <DefaultInput
                :label="$t('marketplaceSettings_googleAnalyticsId.localization_value.value')"
                v-model="MS.data.googleAnalyticsId"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";

export default {
  name: "OtherData",
  components: {
    DefaultInput
  },
  props: {
    MS: Object,
  },

  methods: {
    changeCountry(val) {
      this.MS.data.Delivery.setCountry(val)
    }
  },
}
</script>

<style scoped>

</style>