<template>
  <div class="order-create__section">
    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col">
        <div class="order-create__section-label section-label"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['marketplaceSettings_seo'])"></div>
          {{ $t('marketplaceSettings_seo.localization_value.value') }}
        </div>

        <div class="order-create__row custom-row">
          <div class="order-create__col custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['marketplaceSettings_metaDescription'])"></div>
            <DefaultInput
                :label="$t('marketplaceSettings_metaDescription.localization_value.value')"
                v-model="MS.data.metaDescription"
            />
          </div>

          <div class="order-create__col custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['marketplaceSettings_metaKeywords'])"></div>
            <DefaultInput
                :label="$t('marketplaceSettings_metaKeywords.localization_value.value')"
                v-model="MS.data.metaKeywords"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";

export default {
  name: "Meta",
  components: {DefaultInput},
  props: {
    MS: Object,
  },

  methods: {
  },
}
</script>

<style scoped>

</style>