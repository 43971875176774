<template>
  <div class="order-create__section">
    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col">
        <div class="order-create__section-label section-label"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['marketplaceSettings_media'])"></div>
          {{ $t('marketplaceSettings_media.localization_value.value') }}
        </div>

        <div class="custom-row">
          <div class="custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['marketplaceSettings_logo'])"></div>
            <div class="mb-2 fsz12 font-weight-bold">{{$t('marketplaceSettings_logo.localization_value.value')}}</div>
          </div>
          <div class="custom-col custom-col--16 custom-col--md-50 custom-col--sm-100"
               v-for="(item, index) in MS.data.logoFiles.data.downloadFiles"
               :key="index">
            <div class="site-document d-flex justify-content-center">
              <span class="site-document__remove" @click="removeDocument(item, 'logoFiles')">
                <CloseIcon/>
              </span>
              <div class="site-document__img">
                <img v-if="item.mime_type !== 'application/pdf'"
                     :src="`data:${item.type};base64,` + item.base64"
                     @click="showSingle(item.id, 'logoFiles')"
                     alt="img"
                >
              </div>
            </div>
          </div>

          <div class="custom-col" v-if="MS.data.logoFiles.data.maxCountFiles > MS.data.logoFiles.data.downloadFiles.length">
            <DropZone class="drop-zone-bg"
                      :parentFiles="MS.data.logoFiles.data.files"
                      :multiple="false"
                      :maxCount="MS.data.logoFiles.data.maxCountFiles - MS.data.logoFiles.data.downloadFiles.length"
                      :maxSize="MS.data.logoFiles.data.maxSizeFiles"
                      :accept="'image/x-png,image/gif,image/jpeg'"
                      @changeImg="(files, maxSizeError) => {changeFiles(files, maxSizeError, 'logoFiles')}"
            />
          </div>
        </div>

        <div class="custom-row">
          <div class="custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['marketplaceSettings_banner'])"></div>
            <div class="mb-2 fsz12 font-weight-bold">{{$t('marketplaceSettings_banner.localization_value.value')}}</div>
          </div>
          <div class="custom-col custom-col--16 custom-col--md-50 custom-col--sm-100"
               v-for="(item, index) in MS.data.bannerFiles.data.downloadFiles"
               :key="index">
            <div class="site-document d-flex justify-content-center">
                            <span class="site-document__remove" @click="removeDocument(item, 'bannerFiles')">
                              <CloseIcon/>
                            </span>
              <div class="site-document__img">
                <img v-if="item.mime_type !== 'application/pdf'"
                     :src="`data:${item.type};base64,` + item.base64"
                     @click="showSingle(item.id, 'bannerFiles')"
                     alt="img"
                >
              </div>
            </div>
          </div>

          <div class="custom-col" v-if="MS.data.bannerFiles.data.maxCountFiles > MS.data.bannerFiles.data.downloadFiles.length">
            <DropZone class="drop-zone-bg"
                      :parentFiles="MS.data.bannerFiles.data.files"
                      :multiple="false"
                      :maxCount="MS.data.bannerFiles.data.maxCountFiles - MS.data.bannerFiles.data.downloadFiles.length"
                      :maxSize="MS.data.bannerFiles.data.maxSizeFiles"
                      :accept="'image/x-png,image/gif,image/jpeg'"
                      @changeImg="(files, maxSizeError) => {changeFiles(files, maxSizeError, 'bannerFiles')}"
            />
          </div>
        </div>
      </div>
    </div>

    <Lightbox
        escDisabled
        moveDisabled
        :visible="visible"
        :imgs="imgs"
        :index="index"
        @hide="handleHide"
    ></Lightbox>
  </div>
</template>

<script>
import CloseIcon from '../../../../../../../public/img/modal-group/close-icon.svg?inline'
import DropZone from "../../../../../coreComponents/DropZone/DropZone";
import Lightbox from "vue-easy-lightbox";

export default {
  name: "Media",
  components: {
    DropZone,
    CloseIcon,
    Lightbox
  },
  props: {
    MS: Object,
    optionsCountries: Array,
  },

  data() {
    return {
      logoFilesImages: -1,
      bannerFilesImages: -1,
      imgs: [], // Img Url , string or Array of string
      visible: false,
      index: 0, // default: 0
    }
  },

  methods: {
    changeFiles(files, maxSizeError, filesObjectName) {
      this.MS.data[filesObjectName].setFiles(files)

      this.MS.data[filesObjectName].setFilesMaxSizeError(maxSizeError)
    },

    showSingle(id) {

      this.$store.dispatch('getFileFromServer', id).then((response) => {
        this.imgs = []
        console.log(response);
        this.imgs.push({
          title: 'img',
          src: `data:image/png;base64,` + response[id]
        })
        this.show()
      })
    },

    show() {
      this.visible = true
    },

    handleHide() {
      this.visible = false
    },

    removeDocument(item, fieldName) {
      this.$store.dispatch('removeUserDocument', item.id).then(() => {

        let files = this.MS.data[fieldName]
        files.setDownloadFiles([])
        this[fieldName + 'Images'] = item.id

      })
    },

  },
}
</script>

<style scoped>

</style>