<template>
  <div class="order-create__section">
    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col">
        <div class="order-create__section-label section-label"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['marketplaceSettings_general'])"></div>
          {{ $t('marketplaceSettings_general.localization_value.value') }}
        </div>

<!--        <div v-if="isAdmin" class="order-create__row custom-row">-->
<!--          <div class="order-create__col custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;sm-100"-->
<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <div class="admin-edit" @click="editTranslate(['marketplaceSettings_shopTitle'])"></div>-->
<!--            <DefaultCheckbox-->
<!--                v-model="MS.data.isApproved"-->
<!--                :label="$t('marketplaceSettings_isApproved.localization_value.value')"-->
<!--                @click="MS.data.isApproved = !MS.data.isApproved"-->
<!--            />-->
<!--          </div>-->
<!--        </div>-->

        <div class="order-create__row custom-row">
          <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['marketplaceSettings_shopTitle'])"></div>
            <DefaultInput
                :label="$t('marketplaceSettings_shopTitle.localization_value.value')"
                v-bind:class="{'ui-no-valid': MS.validation.shopTitle}"
                :errorTxt="$t(`${MS.validationTranslate.shopTitle}.localization_value.value`)"
                :error="MS.validation.shopTitle"
                v-model="MS.data.shopTitle"
            />
          </div>

          <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['marketplaceSettings_shopBackground'])"></div>
            <DefaultInput
                :type="'color'"
                :label="$t('marketplaceSettings_shopBackground.localization_value.value')"
                v-bind:class="{'ui-no-valid': MS.validation.profileBackgroundColor}"
                :errorTxt="$t(`${MS.validationTranslate.profileBackgroundColor}.localization_value.value`)"
                :error="MS.validation.profileBackgroundColor"
                v-model="MS.data.profileBackgroundColor"
            />
          </div>

          <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['marketplaceSettings_shopUrl'])"></div>
            <DefaultInput
                :label="$t('marketplaceSettings_shopUrl.localization_value.value')"
                v-bind:class="{'ui-no-valid': MS.validation.url}"
                :errorTxt="$t(`${MS.validationTranslate.url}.localization_value.value`)"
                :error="MS.validation.url"
                v-model="MS.data.url"
            />
          </div>

          <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['marketplaceSettings_taxVatNumber'])"></div>
            <DefaultInput
                :label="$t('marketplaceSettings_taxVatNumber.localization_value.value')"
                v-bind:class="{'ui-no-valid': MS.validation.taxVatNumber}"
                :errorTxt="$t(`${MS.validationTranslate.taxVatNumber}.localization_value.value`)"
                :error="MS.validation.taxVatNumber"
                v-model="MS.data.taxVatNumber"
            />
          </div>

          <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_phone'])"></div>
            <DefaultInput
                :label="$t('common_phone.localization_value.value')"
                v-bind:class="{'ui-no-valid': MS.validation.phone}"
                :errorTxt="$t(`${MS.validationTranslate.phone}.localization_value.value`)"
                :error="MS.validation.phone"
                v-model="MS.data.phone"
            />
          </div>
        </div>

        <div class="order-create__row custom-row">
          <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_address'])"></div>
            <DefaultInput
                :label="$t('common_address.localization_value.value')"
                v-bind:class="{'ui-no-valid': MS.data.Delivery.validation.address}"
                :errorTxt="$t(`${MS.data.Delivery.validationTranslate.address}.localization_value.value`)"
                :error="MS.data.Delivery.validation.address"
                v-model="MS.data.Delivery.delivery.address"
            />
          </div>

          <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_addressSecond'])"></div>
            <DefaultInput
                :label="$t('common_addressSecond.localization_value.value')"
                v-bind:class="{'ui-no-valid': MS.data.Delivery.validation.address2}"
                :errorTxt="$t(`${MS.data.Delivery.validationTranslate.address2}.localization_value.value`)"
                :error="MS.data.Delivery.validation.address2"
                v-model="MS.data.Delivery.delivery.address2"
            />
          </div>

          <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_city'])"></div>
            <DefaultInput
                :label="$t('common_city.localization_value.value')"
                v-bind:class="{'ui-no-valid': MS.data.Delivery.validation.city}"
                :errorTxt="$t(`${MS.data.Delivery.validationTranslate.city}.localization_value.value`)"
                :error="MS.data.Delivery.validation.city"
                v-model="MS.data.Delivery.delivery.city"
            />
          </div>

          <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_region'])"></div>
            <DefaultInput
                :label="$t('common_region.localization_value.value')"
                v-bind:class="{'ui-no-valid': MS.data.Delivery.validation.region}"
                :errorTxt="$t(`${MS.data.Delivery.validationTranslate.region}.localization_value.value`)"
                :error="MS.data.Delivery.validation.region"
                v-model="MS.data.Delivery.delivery.region"
            />
          </div>

          <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_country'])"></div>
            <DefaultSelect
                :options="optionsCountries"
                :label="$t('common_country.localization_value.value')"
                v-bind:class="{'ui-no-valid': MS.data.Delivery.validation.country}"
                :errorTxt="$t(`${MS.data.Delivery.validationTranslate.country}.localization_value.value`)"
                :error="MS.data.Delivery.validation.country"
                :selected="MS.data.Delivery.delivery.country"
                :optionsLabel="'label'"
                @change="changeCountry"
            />
          </div>

          <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_zipCode'])"></div>
            <DefaultInput
                :label="$t('common_zipCode.localization_value.value')"
                v-bind:class="{'ui-no-valid': MS.data.Delivery.validation.zip}"
                :errorTxt="$t(`${MS.data.Delivery.validationTranslate.zip}.localization_value.value`)"
                :error="MS.data.Delivery.validation.zip"
                v-model="MS.data.Delivery.delivery.zip"
            />
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";
import DefaultSelect from "../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
// import DefaultCheckbox from "../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
export default {
  name: "General",
  components: {
    // DefaultCheckbox,
    DefaultSelect,
    DefaultInput
  },
  props: {
    MS: Object,
    optionsCountries: Array,
  },

  methods: {
    changeCountry(val) {
      this.MS.data.Delivery.setCountry(val)
    }
  },
}
</script>

<style scoped>

</style>