<template>
  <div class="order-create__section">
    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col">
        <div class="order-create__section-label section-label"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['marketplaceSettings_socialLinks'])"></div>
          {{ $t('marketplaceSettings_socialLinks.localization_value.value') }}
        </div>

        <div class="order-create__row custom-row">
          <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100">
            <DefaultInput
                :label="'Twitter Id'"
                v-model="MS.data.twitterId"
            />
          </div>

          <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100">
            <DefaultInput
                :label="'Facebook Id'"
                v-model="MS.data.facebookId"
            />
          </div>

          <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100">
            <DefaultInput
                :label="'Youtube Id'"
                v-model="MS.data.youtubeId"
            />
          </div>

          <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100">
            <DefaultInput
                :label="'Instagram Id'"
                v-model="MS.data.instagramId"
            />
          </div>

          <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100">
            <DefaultInput
                :label="'Skype Id'"
                v-model="MS.data.skypeId"
            />
          </div>

          <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100">
            <DefaultInput
                :label="'LinkedIn'"
                v-model="MS.data.linkedInId"
            />
          </div>

          <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100">
            <DefaultInput
                :label="'Pinterest Id'"
                v-model="MS.data.pinterestId"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";

export default {
  name: "Social",
  components: {DefaultInput},
  props: {
    MS: Object,
  },

  methods: {
  },
}
</script>

<style scoped>

</style>