import {checkValid} from "@/components/globalModels/functions/ModelsValidate";
import {Delivery} from "../../../globalModels/Delivery";
import {Files} from "../../../globalModels/Files";


export function MarketplaceSettings() {

  this.checkValid = checkValid

  this.data = {
    id: '',
    shopId: '',
    url: '',
    isApproved: false,
    shopTitle: '',
    description: '',
    taxVatNumber: '',

    metaTitle: '',
    metaDescription: '',
    metaKeywords: '',

    Delivery: new Delivery(),
    phone: '',

    returnPolicy: '',
    shippingPolicy: '',
    privacyPolicy: '',

    twitterId: '',
    facebookId: '',
    instagramId: '',
    youtubeId: '',
    skypeId: '',
    linkedInId: '',
    pinterestId: '',
    googleAnalyticsId: '',

    customerId: '',

    commissionEnable: false,
    commissionPercentage: 0,

    minOrderAmount: null,
    profileBackgroundColor: '',

    logoFiles: new Files({
      maxCountFiles: 1,
      maxSizeFiles: 3
    }),

    bannerFiles: new Files({
      maxCountFiles: 1,
      maxSizeFiles: 3
    }),

  }

  this.validation = {
    url: false,
    // isApproved: false,
    shopTitle: false,
    profileBackgroundColor: false,
    // description: false,
    taxVatNumber: false,
    // metaTitle: false,
    // metaDescription: false,
    // metaKeywords: false,
    // address1: false,
    // address2: false,
    // phone: false,
    // state: false,
    // city: false,
    // country: false,
    // zip: false,
    // returnPolicy: false,
    // shippingPolicy: false,
    // privacyPolicy: false,
  }

  this.validationTranslate = {
    url: '',
    shopTitle: '',
    profileBackgroundColor: '',
    taxVatNumber: '',
  }

  this.validationTxt = {
    url: false,
    shopTitle: false,
    profileBackgroundColor: false,
    taxVatNumber: false,
  }

  /**
   * Getters
   */
  this.getId = () => {
    return this.data.id
  }
  this.getShopId = () => {
    return this.data.shopId
  }
  this.getUrl = () => {
    return this.data.url
  }
  this.getShopTitle = () => {
    return this.data.shopTitle
  }
  this.getDescription = () => {
    return this.data.description
  }
  this.getTaxVatNumber = () => {
    return this.data.taxVatNumber
  }
  this.getPhone = () => {
    return this.data.phone
  }
  this.getMetaTitle = () => {
    return this.data.metaTitle
  }
  this.getMetaDescription = () => {
    return this.data.metaDescription
  }
  this.getMetaKeywords = () => {
    return this.data.metaKeywords
  }
  this.getReturnPolicy = () => {
    return this.data.returnPolicy
  }
  this.getShippingPolicy = () => {
    return this.data.shippingPolicy
  }
  this.getPrivacyPolicy = () => {
    return this.data.privacyPolicy
  }
  this.getTwitterId = () => {
    return this.data.twitterId
  }
  this.getFacebookId = () => {
    return this.data.facebookId
  }
  this.getInstagramId = () => {
    return this.data.instagramId
  }
  this.getYoutube = () => {
    return this.data.youtubeId
  }
  this.getSkypeId = () => {
    return this.data.skypeId
  }
  this.getLinkedInId = () => {
    return this.data.linkedInId
  }
  this.getPinterestId = () => {
    return this.data.pinterestId
  }
  this.getGoogleAnalyticsId = () => {
    return this.data.googleAnalyticsId
  }
  this.getCustomerId = () => {
    return this.data.customerId
  }
  this.getCommissionPercentage = () => {
    return this.data.commissionPercentage
  }
  this.getMinOrderAmount = () => {
    return this.data.minOrderAmount
  }
  this.getProfileBackgroundColor = () => {
    return this.data.profileBackgroundColor
  }

  /**
   * Setters
   */
 
  this.setId = (val) => {
    this.data.id = val
  }
  this.setShopId = (val) => {
    this.data.shopId = val
  }
  this.setUrl = (val) => {
    this.data.url = val
  }
  this.setShopTitle = (val) => {
    this.data.shopTitle = val
  }
  this.setDescription = (val) => {
    this.data.description = val
  }
  this.setTaxVatNumber = (val) => {
    this.data.taxVatNumber = val
  }
  this.setPhone = (val) => {
    this.data.phone = val
  }
  this.setMetaTitle = (val) => {
    this.data.metaTitle = val
  }
  this.setMetaDescription = (val) => {
    this.data.metaDescription = val
  }
  this.setMetaKeywords = (val) => {
    this.data.metaKeywords = val
  }
  this.setReturnPolicy = (val) => {
    this.data.returnPolicy = val
  }
  this.setShippingPolicy = (val) => {
    this.data.shippingPolicy = val
  }
  this.setPrivacyPolicy = (val) => {
    this.data.privacyPolicy = val
  }
  this.setTwitterId = (val) => {
    this.data.twitterId = val
  }
  this.setFacebookId = (val) => {
    this.data.facebookId = val
  }
  this.setInstagramId = (val) => {
    this.data.instagramId = val
  }
  this.setYoutubeId = (val) => {
    this.data.youtubeId = val
  }
  this.setSkypeId = (val) => {
    this.data.skypeId = val
  }
  this.setLinkedInId = (val) => {
    this.data.linkedInId = val
  }
  this.setPinterestId = (val) => {
    this.data.pinterestId = val
  }
  this.setGoogleAnalyticsId = (val) => {
    this.data.googleAnalyticsId = val
  }
  this.setCustomerId = (val) => {
    this.data.customerId = val
  }
  this.setCommissionPercentage = (val) => {
    this.data.commissionPercentage = val
  }
  this.setMinOrderAmount = (val) => {
    this.data.minOrderAmount = val
  }
  this.setProfileBackgroundColor = (val) => {
    this.data.profileBackgroundColor = val
  }
  
}


/**
 * Global Setters
 */

MarketplaceSettings.prototype.setItem = function (item, countries) {
  console.log(item, countries);
  this.setCustomerId(item.customer_id)
  this.setShopTitle(item.shop_title)
  this.setProfileBackgroundColor(item.profile_background ? item.profile_background : '#000000')
  this.setUrl(item.url)
  this.setTaxVatNumber(item.tax_vat)
  this.setPhone(item.phone)

  this.data.isApproved = item.is_approved === 1

  this.data.Delivery.setAddress(item.address1)
  this.data.Delivery.setAddress2(item.address2)
  this.data.Delivery.setCity(item.city)
  this.data.Delivery.setRegion(item.state)
  this.data.Delivery.setCountry(countries.find(countryItem => {
    return countryItem.code_iso_2 === item.country
  }) || null)
  this.data.Delivery.setZip(item.postcode)

  this.setDescription(item.description)

  this.setTwitterId(item.twitter)
  this.setFacebookId(item.facebook)
  this.setYoutubeId(item.youtube)
  this.setInstagramId(item.instagram)
  this.setSkypeId(item.skype)
  this.setLinkedInId(item.linked_in)
  this.setPinterestId(item.pinterest)

  this.setReturnPolicy(item.return_policy)
  this.setShippingPolicy(item.shipping_policy)
  this.setPrivacyPolicy(item.privacy_policy)

  this.setMetaDescription(item.meta_description)
  this.setMetaKeywords(item.meta_keywords)

  this.setMinOrderAmount(item.min_order_amount)
  this.setGoogleAnalyticsId(item.google_analytics_id)

}


/**
 * Functions
 */

/**
 * Validations
 */
MarketplaceSettings.prototype.firstValidationAdmin = function () {
  let validationItems = {
    url: this.getUrl(),
    shopTitle: this.getShopTitle(),
    phone: this.getPhone(),
  }

  let validationOptions = {
    url: {type: ['latin', 'empty']},
    shopTitle: {type: ['empty']},
    phone: {type: ['empty']},
  }

  let validateDelivery = this.data.Delivery.deliveryInfoValidate()

  return (this.checkValid(validationItems, validationOptions) && validateDelivery)
}

/**
 * Prepare Data
 */

MarketplaceSettings.prototype.prepareSave = function () {

  let data = {
    // 'is_approved': this.data.isApproved,
    'url': this.getUrl(),
    'shop_title': this.getShopTitle(),
    'description': this.getDescription(),
    'tax_vat': this.getTaxVatNumber(),
    //'meta_title': null,
    'meta_description': this.getMetaDescription(),
    'meta_keywords': this.getMetaKeywords(),
    'address1': this.data.Delivery.getAddress(),
    'address2': this.data.Delivery.getAddress2(),
    'phone': this.getPhone(),
    'state': this.data.Delivery.getRegion(),
    'city': this.data.Delivery.getCity(),
    'country': this.data.Delivery.getCountry()?.code_iso_2 || null,
    'postcode': this.data.Delivery.getZip(),
    'return_policy': this.getReturnPolicy(),
    'shipping_policy': this.getShippingPolicy(),
    'privacy_policy': this.getPrivacyPolicy(),
    'twitter': this.getTwitterId(),
    'facebook': this.getFacebookId(),
    'youtube': this.getYoutube(),
    'instagram': this.getInstagramId(),
    'skype': this.getSkypeId(),
    'linked_in': this.getLinkedInId(),
    'pinterest': this.getPinterestId(),
    'customer_id': this.getCustomerId(),
    //'commission_enable': null,
    //'commission_percentage': null,
    'min_order_amount': this.getMinOrderAmount(),
    'google_analytics_id': this.getGoogleAnalyticsId(),
    'profile_background': this.getProfileBackgroundColor(),
  }

  // if (admin === true) {
  //   data['is_approved'] = this.data.isApproved
  // }

  return data

}
