<template>
  <!--v-if="$store.getters.getIsAdminRights === 'user'"-->
  <div>
    <div class="detail-page">
      <div class="detail-page__left">
        <CardLeftBlock
            :backgroundImage="'shops'"
            class="shops"
        >
        </CardLeftBlock>
      </div>
      <div class="detail-page__right">
        <MarketplaceSettingsEditUser
            class="product-edit-left"
            :MS="MS"
            :optionsCountries="optionsCountries"
            @save="saveMarketplaceSettings(isAdmin)"
        />
      </div>
    </div>
  </div>

</template>

<script>
  import MarketplaceSettingsEditUser from "./MarketplaceSettingsEditUser/MarketplaceSettingsEditUser";
  import {MarketplaceSettings} from "../../models/MarketplaceSettings";
  import {marketplaceSettingsMixin} from "../../../../../mixins/marketplaceSettingsMixins/marketplaceSettingsMixin";
  import {filterCountriesUSA} from "../../../../../services/filterCountries";
  import CardLeftBlock from "../../../../coreComponents/CardLeftBlock/CardLeftBlock";

  export default {
    name: "MarketplaceSettingsEdit",

    components: {
      CardLeftBlock,
      MarketplaceSettingsEditUser,
    },

    mixins: [marketplaceSettingsMixin],

    data() {
      return {
        MS: new MarketplaceSettings(),
        optionsCountries: [],
      }
    },

    created() {

    },

    mounted() {
      this.MS.setShopId(this.$route.params.id)
      if (this.MS.getShopId()) {
        this.$store.dispatch('getMarketplaceSettings', this.MS.getShopId()).then((mpResponse) => {
          this.$store.dispatch('fetchCountries').then(() => {
            let countries = this._.toArray(this.$store.getters.getCountries)
            filterCountriesUSA(countries)
            this.optionsCountries = countries;
            this.MS.setItem(mpResponse?.data?.data?.settings, countries)

            this.getFileFromServer(mpResponse?.data?.data?.settings?.file_entities).then(files => {
              let logoFile = files.find(lFile => {
                return lFile.fileType === 'marketplaceSettingsLogoFileType'
              })
              this.MS.data.logoFiles.setDownloadFiles(logoFile ? [logoFile] : [])

              let bannerFile = files.find(lFile => {
                return lFile.fileType === 'marketplaceSettingsBannerFileType'
              })
              this.MS.data.bannerFiles.setDownloadFiles(bannerFile ? [bannerFile] : [])
            })

          }).catch(error => this.createErrorLog(error))
        })
      }
    },

    methods: {
      async getFileFromServer(files){
        if(!files) return
        let downloadFiles = []

        for await (let file of files) {
          await this.$store.dispatch('getFileFromServer', file.id)
              .then((response) => {
                let fileBase64 = response
                Object.keys(response).map(item => {
                  console.log('item id: ', item);
                  downloadFiles.push({
                    id: item,
                    type: file.mime_type,
                    uuid: file.uuid,
                    original_filename: file.original_filename,
                    base64: fileBase64[item],
                    fileType: file.type,
                  })
                })
              })
        }

        return Promise.resolve(downloadFiles)

      },

    },

  }
</script>

<style scoped>
  @media (max-width: 992px) {
    .product-edit-left >>> .card-detail-left__bg--home {
      background-image: none;
    }
  }
</style>
