
export const marketplaceSettingsMixin = {

  methods: {
    saveMarketplaceSettings(admin) {
      if(!this.MS.firstValidationAdmin()) return

      let data = {
        id: this.MS.getShopId(),
        data: this.MS.prepareSave(admin),
      }

      this.$store.dispatch('updateMarketplaceSettings', data).then(response => {
        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            if(this.MS.data.logoFiles.getFiles().length > 0 || this.MS.data.bannerFiles.getFiles().length > 0) {
              this.createFiles()
              return
            }

            this.openNotify('success', 'common_notificationRecordChanged')

            this.$router.push(this.$store.getters.GET_PATHS.mainSettingsShops)
            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },

    createFiles() {
      // let data = {}
      let reader = new FormData();
      // let files = []
      this.MS.data.logoFiles.data.files.map((item) => {
        if(!this._.has(item, 'maxCount') && !item.maxSizeError){
          // files.push(item)
          console.log(item);
          reader.append(`file[logo]`, item.file);
        }
      })
      this.MS.data.bannerFiles.data.files.map((item) => {
        if(!this._.has(item, 'maxCount') && !item.maxSizeError){
          // files.push(item)
          console.log(item);
          reader.append(`file[banner]`, item.file);
        }
      })

      let data = {
        id: this.MS.getShopId(),
        data: reader
      }

      this.$store.dispatch('addPhotoToMarketplaceSettings', data).then((response) => {

        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            this.openNotify('success', 'common_notificationRecordChanged')

            this.$router.push(this.$store.getters.GET_PATHS.mainSettingsShops)
            break
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }

      }).catch(error => this.createErrorLog(error))
    },
  }

}
