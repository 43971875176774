<template>
  <CardRightBlock>
    <template slot="header">
      <div class="order-create__head">
        <div class="order-create__head-line">
          <div class="order-create__header-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['shops_backToAllShops'])"></span>
            <router-link :to="$store.getters.GET_PATHS.mainSettingsShops" class="order-create__head-back">
              <LinkBack
                  :value="$t('shops_backToAllShops.localization_value.value')"
              />
            </router-link>
          </div>
        </div>
        <div class="order-create__head-title"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['marketplaceSettings_general'])"></span>
          {{$t('marketplaceSettings_settings.localization_value.value')}}
        </div>
      </div>
    </template>
    <template slot="body">
      <General
          :MS="MS"
          :optionsCountries="optionsCountries"
      />

      <Media
          :MS="MS"
      />

      <div class="order-create__section">
        <div class="order-create__row custom-row">
          <div class="order-create__col custom-col">
            <div class="order-create__section-label section-label"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['marketplaceSettings_seo'])"></div>
              {{ $t('marketplaceSettings_aboutShop.localization_value.value') }}
            </div>

            <div class="order-create__row custom-row">
              <div class="order-create__col custom-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_address'])"></div>
                <TextEditor
                    :value="MS.data.description"
                    @input="inputTextEditorDescr"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Social
          :MS="MS"
      />

      <Policies
          :MS="MS"
      />

      <Meta
          :MS="MS"
      />

      <OtherData
          :MS="MS"
      />

    </template>
    <template slot="footer">
      <div class="order-create__footer">
        <div class="order-create__footer-btn ml-auto"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_create'])"></div>
          <MainButton
              class="order-create__footer-btn-i"
              :value="$t('common_update.localization_value.value')"
              @click.native="$emit('save')"
              v-bind:class="{'disabled-btn' : $store.getters.getMarketplaceSettingsBtn}"
          />
        </div>
      </div>
    </template>

  </CardRightBlock>
</template>

<script>
  import MainButton from "../../../../../UI/buttons/MainButton/MainButton";
  import General from "../../chunks/General/General";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock";
  import Meta from "../../chunks/Meta/Meta";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack";
  import TextEditor from "../../../../../coreComponents/TextEditor/TextEditor";
  import Social from "../../chunks/Social/Social";
  import Policies from "../../chunks/Policies/Policies";
  import OtherData from "../../chunks/OtherData/OtherData";
  import Media from "../../chunks/Media/Media";

  export default {
    name: "MarketplaceSettingsEditUser",

    components: {
      Media,
      OtherData,
      Policies,
      Social,
      TextEditor,
      LinkBack,
      CardRightBlock,
      General,
      Meta,
      MainButton,
    },

    mixins: [],

    props: {
      MS: Object,
      optionsCountries: Array,
    },

    data() {
      return {

      }
    },

    methods: {
      inputTextEditorDescr(val) {
        this.MS.setDescription(val)
      }
    }
  }
</script>

<style lang="scss">

  .hide-upc {
    opacity: 0.5;
    pointer-events: none;
  }

  .product-progress-steps {
    max-width: 625px;
  }

  .card-detail-left__username {
    margin-top: 10px;
    font-weight: 500;
  }

</style>
