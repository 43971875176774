<template>
  <div class="order-create__section">
    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col">
        <div class="order-create__section-label section-label"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['marketplaceSettings_policies'])"></div>
          {{ $t('marketplaceSettings_policies.localization_value.value') }}
        </div>

        <div class="order-create__row custom-row">
          <div class="order-create__col custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['marketplaceSettings_returnPolicy'])"></div>
            <div class="mb-2 fsz12 font-weight-bold">{{$t('marketplaceSettings_returnPolicy.localization_value.value')}}</div>
            <TextEditor
                :value="MS.data.returnPolicy"
                @input="MS.setReturnPolicy"
            />
          </div>

          <div class="order-create__col custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['marketplaceSettings_shippingPolicy'])"></div>
            <div class="mb-2 fsz12 font-weight-bold">{{$t('marketplaceSettings_shippingPolicy.localization_value.value')}}</div>
            <TextEditor
                :value="MS.data.shippingPolicy"
                @input="MS.setShippingPolicy"
            />
          </div>

          <div class="order-create__col custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['marketplaceSettings_privacyPolicy'])"></div>
            <div class="mb-2 fsz12 font-weight-bold">{{$t('marketplaceSettings_privacyPolicy.localization_value.value')}}</div>
            <TextEditor
                :value="MS.data.privacyPolicy"
                @input="MS.setPrivacyPolicy"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextEditor from "../../../../../coreComponents/TextEditor/TextEditor";

export default {
  name: "Policies",
  components: {TextEditor},
  props: {
    MS: Object,
  },

  methods: {
  },
}
</script>

<style scoped>

</style>